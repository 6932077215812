import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

// disable navbar and footer when in login page
export const disableNavWithFooter = ["/login"]

export const getFixedPercentage = (numerator,denominator) => {
  const decimalValue = numerator / denominator
  return Math.round(decimalValue * 100) 
}